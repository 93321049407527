<template>
	<div>
		<router-view></router-view>
		<cookie-law theme="base" button-text="Kabul et" button-class="cookie-button" storage-type="cookies">
			<div slot="message">
				Ziyaretiniz sırasında kişisel verileriniz siteyi kullanımınızı analiz etmek amacıyla çerezler aracılığıyla işlenmektedir.
			</div>
		</cookie-law>
	</div>
</template>
<script>
import { checkHasUI, initChatBot, initFacebookPixel } from '@/common/siteInit';
import CookieLaw from 'vue-cookie-law';
import { mapGetters } from 'vuex';

export default {
	name: 'Base',
	components: { CookieLaw },
	computed: {
		...mapGetters(['chatbot', 'getSite', 'hasUI']),
		pixel() {
			return this.getSite.pixel;
		},
	},
	beforeMount() {
		checkHasUI(this.$router, this.getSite);
	},
	mounted() {
		this.$nextTick(() => {
			initChatBot(this.chatbot);
			initFacebookPixel(this.pixel);
		});
	},
};
</script>

<style lang="scss">
.cookie-button {
	background-color: var(--v-button-color-base);
	color: var(--v-button-text-base);
	padding: 10px 20px;
}
</style>
